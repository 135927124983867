@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply bg-slate-50;
}


@font-face {
	font-family: "MV_Faseyha";
	src: url("../public/fonts/mv_faseyha.otf");
}

@font-face {
	font-family: MV Aammu FK;
	src: url("../public/fonts/mv-aammu-fk.ttf");
}

@font-face {
	font-family: "MV_Waheed";
	src: url("../public/fonts/mv_waheed.otf");
}

.dir-rtl {
	direction: rtl;
}
